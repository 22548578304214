.absolute__footer {
    text-align: center;
    padding: 20px 0;
    background: color(base, White);

    a {
        color: color(base, DefaultFontColor);
        font-size: 1.4rem;
        padding-left: 1rem;

        &:before {
            content: "|";
            padding-right: 1.2rem;
        }

        &:first-of-type {
            padding: 0;
            display: block;
            text-align: left;

            &:before {
                display: none;
            }
        }

        &:hover {
            text-decoration: underline;
        }

        @include breakpoint(small only) {
            &:last-of-type {
                display: block;
                padding: 0;
                margin: 1rem 0 0;

                &:before {
                    display: none;
                }
            }
        }
    }
}
