.homepage__fullscreen-two-column--block-wrapper {
    height: 100%;
    > div {
        height: 100%;
        background-size: cover;

        .--text-block-padding{
            .--content{
                padding:1.6rem;
            }
        }

        @include breakpoint(small only) {
            .--content {
                margin: 0 0 2rem;
                *:not(h2) {
                    display: none;
                }
                h2 {
                    text-align: center;
                }
            }
        }
    }
}
