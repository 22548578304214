.page-builder__wrapper {
    overflow: hidden;
    @include breakpoint(medium) {
        margin: 2rem 0 0;
    }

    .page-builder__content-block:not(.--first) {
        margin: 2rem 0;
        @include breakpoint(medium) {
            margin: 7rem 0;
        }

        &:not(.--last) {
            .horizontal__line {
                margin: 4rem 0 0;
                border-bottom: .5rem solid color(base, DefaultFontColor);

                @include breakpoint(medium) {
                    margin: 7rem 0 0;
                }
            }
        }
    }
    .--header {
        margin: 0 0 3rem;

        h3 {
            text-align: center;
            font-size: 2.4rem;
        }
    }

}

.fullpage-wrapper {
    .flex__columns {
        max-width: 110rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }

    .--content {

        h2 {
            font-size: clamp(4.2rem, -5rem + 13.6vw, 5.6rem);
            line-height: clamp(4rem, -5rem + 13.6vw, 5rem);
            text-transform: uppercase;
            color: color(base, White);
            text-align: right;
        }

        p {
            margin: 3rem 0 0;
            font-size: clamp(1.8rem, 0.6rem + 1.6vw, 2.2rem);
            line-height: clamp(3rem, 0.6rem + 1.6vw, 3.4rem);
            color: color(base, White);
            text-align: right;
        }

        > a, > button {
            margin-top: 3rem;
        }
    }

    .--animation {
        opacity: 0;
        filter: blur(.25rem);

        &.--slide-from-right {
            text-align: left;
            position: relative;
            z-index: 2;

            @include breakpoint(medium) {
                padding: 0 0 0 4rem;
            }
        }

        &.--slide-from-left {
            text-align: right;
            position: relative;
            z-index: 3;

            @include breakpoint(medium) {
                padding: 0 4rem 0 0;
            }
        }
    }

    .active {
        .--animation {
            opacity: 1;
            filter: blur(0);

            &.--slide-from-right {
                animation: SlideFromRight .8s;
            }

            &.--slide-from-left {
                animation: SlideFromLeft .8s;
            }
        }
    }
    .fp-overflow {
        height: calc(100% - 16rem);
    }
    .fp-table {
        justify-content: end;
    }
}
.fullscreen_body{
    header {
        z-index: 1;
        position: fixed;
        left: 0;
        right: 0;
    }
}
