.page-builder__wrapper {
    .page-builder__content-block:not(.--first):not(.--last) {
        .horizontal__line {
            border-bottom: .5rem solid color(Motoport, MotoGrey);
        }
    }
    .fp-section:last-child{
        padding-top:0;
    }
}
