.homepage__content--block-wrapper {
    h2 {
        text-transform: uppercase;
        font-size: clamp(5.1rem, 5vw, 8rem);
        line-height: 1;
        font-weight: 900;
        font-style: italic;
    }

    h3 {
        text-transform: uppercase;
        font-size: clamp(2.8rem, 5vw, 4rem);
        line-height: 1;
        font-weight: 500;
    }

    h4 {
        text-transform: uppercase;
        font-size: clamp(2rem, 5vw, 3rem);
        line-height: 1;
        font-weight: normal;
    }

    .image__block {
        position: relative;

        h2 {
            position: absolute;
            z-index: 2;
            color: color(base, White);
            margin: 0;

            &.a-left {
                top: 5%;
                left: 5%;
            }

            &.a-center {
                top: 5%;
                right: 50%;
                transform: translateX(50%);
            }

            &.a-right {
                top: 5%;
                right: 5%;
            }
        }
    }

    .--content {
        @include breakpoint(medium) {
            padding-right: 30%;
            &.--right {
                padding-left: 30%;
                padding-right: 0;
            }
        }

        .--header {
            width: fit-content;
            display: flex;
            flex-flow: column;
            gap: 1rem;
        }

        p {
            font-size: clamp(1.6rem, 4vw, 2rem);
            line-height: 1.5;
            margin: 1.5rem 0 2rem;

            @include breakpoint(medium) {
                margin: 3rem 0 0;
            }
        }
    }

    .--media {
        iframe {
            width: 100%;
            height: 100%;
        }

        @include breakpoint(medium) {
            img, > div {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            iframe {
                height: 532px;
            }
        }
    }

    .--in-view {
        img {
            opacity: 1;
            transform: scale(1);
        }
    }
}
