.homepage__fullscreen-one-column--block-wrapper {
    height: 100%;
    overflow: hidden;
    position: relative;

    .video__column {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > div {
        height: 100%;
        background-size: cover;
        background-position: center center;

        .fullpage-wrapper {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 1rem;

            @include breakpoint(medium) {
                padding: 0 11rem;
            }

            &.--left {
                justify-content: flex-start;

                .--content {
                    text-align: left;

                    > * {
                        text-align: left;
                    }
                }
            }

            &.--right {
                justify-content: flex-end;

                .--content {
                    text-align: right;

                    > * {
                        text-align: right;
                    }
                }
            }

            &.--center {
                justify-content: center;

                .--content {
                    text-align: center;

                    > * {
                        text-align: center;
                    }
                }
            }

            .--content {
                max-width: 47rem;
                &.--text-block-padding{
                    padding:1.6rem;
                }
            }
        }
    }
}
