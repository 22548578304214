.customer-quick-buy {
    .import__products {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .cart__import-off-canvas {
        width: 60rem;

        &:not(.is-open) {
            transform: translateX(-60rem);
        }

        .modal__content {
            display: flex;
            flex-flow: column;
            height: calc(100% - 4rem);

            .cart__import--loader {
                .--loading {
                    @include absolute-loading('light');
                }
            }

            .mobile__scrollable {
                flex: 1;
                max-height: calc(100% - 8rem);
                overflow-y: auto;

                .--center {
                    display: flex;
                    justify-content: center;
                }
            }

            .cart__import__actions {
                .--message {
                    display: block;
                    background-repeat: no-repeat;
                    background-size: 1.6rem;
                    padding-left: 2rem;
                    background-position: left center;
                    font-size: 1.4rem;

                    &.--failed {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, Red)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                        color: color(base, Red);
                    }

                    &.--success {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, Green)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 12.75 6 6 9-13.5' /%3E%3C/svg%3E%0A");
                        color: color(base, Green);
                    }
                }
            }

            .--messages {
                padding: 2rem;
            }

            .--actions {
                bottom: 0;
                left: 0;
                right: 0;
                position: fixed;
                background: color(Gray, Gray-25);
                border-top: .1rem solid color(Gray, Gray-100);
                display: flex;
                padding: 2rem;
                justify-content: center;
                align-items: center;
                gap: 2rem;
            }
        }
    }

    .product__quick-buy--wrapper {
        margin: 2.5rem 0 0;
        border-top: .1rem solid color(Gray, Gray-600);

        .--header {
            margin: 2.5rem 0 1rem;
            display: flex;
            justify-content: space-between;

            h2 {
                font-size: 1.6rem;
                font-weight: 700;
            }

            .--sorter {
                @include breakpoint(small only) {
                    min-width: auto;
                    justify-content: start;
                }
            }
        }

        .quick-buy__result {
            margin: 0 0 2.5rem;
        }

        section {
            > .--actions {
                padding: 3rem 0 1rem;
                display: flex;
                justify-content: center;
                @include breakpoint(small only) {
                    justify-content: end;
                    padding: 1rem 0;
                }
            }
        }
    }

    .result__header {
        margin: 1.5rem 0;

        h2 {
            font-size: 2.1rem;
            font-weight: 700;
        }

        + ._alternative {
            position: relative;

            .--loading {
                @include absolute-loading('light');
            }
        }
    }

    .quick__buy__table {
        th.--checkbox {
            padding: 0 17px;
        }

        .--checkbox-row {
            display: flex;
            align-items: center;
            gap: .5rem;
            @include breakpoint(small only) {
                flex-direction: column;
            }

            label {
                cursor: pointer;
            }
        }

        .dashboard__form {
            display: inline-flex;
            @include breakpoint(small only) {
                display: flex;
                flex-direction: column;
                padding: 1rem 0;
                border: none;
            }
            border: .1rem solid color(Gray, Gray-100);
            padding: .5rem 1rem;
            gap: 1rem;

            .quote__input {
                @include breakpoint(small only) {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                }

                label {
                    display: block;
                    font-size: 1.1rem;
                    font-weight: 600;
                    @include breakpoint(small only) {
                        width: 7rem;
                    }
                }

                .--save {
                    width: 3.2rem;
                    height: 3.2rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{color(base, White)}' height='16' width='14' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 1.6rem;
                    @include breakpoint(small only) {
                        width: 200px;
                        background-position: 95%;
                        align-items: center;
                    }

                    span {
                        display: none;
                        @include breakpoint(small only) {
                            display: block;
                        }
                    }
                }

                .--loading {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='135' height='140' viewBox='0 0 135 140' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Crect y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.5s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.5s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='30' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.25s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.25s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='60' width='15' height='140' rx='6'%3E%3Canimate attributeName='height' begin='0s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='90' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.25s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.25s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='120' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.5s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.5s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3C/svg%3E");

                    &::after {
                        display: none;
                    }
                }
            }
        }

        @include breakpoint(small only) {
            .dashboard__form.--collapsed {
                display: none;
            }
            tr:first-child {
                border-bottom: none;
            }

            tr:first-child.--expanded {
                border-bottom: 1px solid #ccc;
            }

            tr.--expanded {
                background-color: rgba(color(base, PrimaryButton), 0.1);
            }

            .sticky-expand-button {
                vertical-align: middle;

                span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m8.25 4.5 7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #d51317;
                    background-size: 1.8rem;
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    padding: 1rem;
                    transition: transform 0.2s ease;
                    margin: 0 auto;
                }

                .--rotated {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .quick-buy__search-results {
        margin: 2.5rem 0 0;
        border-top: .1rem solid color(Gray, Gray-600);
    }

    .quick-buy__actions {
        margin: 2.5rem 0 0;

        .bulk__holder {
            @include border-radius();
            background: color(Gray, Gray-25);
            border: .1rem solid color(Gray, Gray-100);
            padding: 2rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .input__box {
                display: flex;
                gap: 2rem;
                align-items: center;
                @include breakpoint(small only) {
                    flex-direction: column;
                    align-items: start;
                    gap: 1rem;
                }

                label {
                    font-size: 1.4rem;
                    flex: 0 0 30%;
                }

                .input__holder {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    input {
                        max-width: 17rem;
                    }

                    &.--alternative {
                        input {
                            max-width: 6rem;
                        }
                    }
                }
            }

            .--actions {
                display: flex;
                align-items: center;
                gap: 1rem;
                margin: 2rem 0 0;

                button {
                    background-size: 1.6rem;
                    background-repeat: no-repeat;
                    background-position: left 1rem center;
                    padding-left: 3.5rem;

                    &.--update {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99' /%3E%3C/svg%3E%0A");
                    }

                    &.--remove {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0' /%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        .--quick-buy-cta {
            margin: 2.5rem 0 0;

            &.--disabled {
                pointer-events: none;
            }
        }

        .quick-buy__comments {
            .input__box {
                margin-bottom: 1.5rem;
            }

            input {
                width: 100%;
                margin: 1rem 0 0;
            }

            textarea {
                resize: none;
                min-height: 12rem;
                width: 100%;
                margin: 1rem 0 0;
            }
        }
    }

    .quick__menu {
        @include transition();
        z-index: 5;
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 2rem;

        > span {
            @include transition();
            align-items: center;
            background-color: color(base, Primary);
            border-radius: 50%;
            color: color(base, White);
            cursor: pointer;
            display: flex;
            height: 4rem;
            justify-content: center;
            width: 4rem;
            background-repeat: no-repeat;
            background-size: 2.4rem;
            background-position: center;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12' /%3E%3C/svg%3E%0A");

            @include hover() {
                background-color: color(base, PrimaryHover);
            }
        }

        > nav {
            transition: max-height .3s ease-in-out;
            width: 4rem;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            max-height: 0;

            > span {
                @include transition();
                display: block;
                width: 3rem;
                height: 3rem;
                background-color: color(base, White);
                border-radius: 50%;
                background-repeat: no-repeat;
                background-size: 1.8rem;
                background-position: center;

                &.--top {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18' /%3E%3C/svg%3E%0A");
                }

                &.--quick-buy {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z' /%3E%3C/svg%3E%0A");
                }

                &.--cart {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z' /%3E%3C/svg%3E%0A");
                }

                &.--mass-actions {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3' /%3E%3C/svg%3E%0A");
                }

                @include hover() {
                    cursor: pointer;
                    background-color: color(Gray, Gray-200);
                }
            }
        }

        &.--active {
            background-color: color(base, PrimaryHover);

            > span {
                background-color: color(base, PrimaryHover);
                transform: rotate(180deg);
                background-size: 2rem;
            }

            > nav {
                max-height: 16rem;
                padding: .2rem 0 .7rem;
            }
        }
    }
}

.quick-buy__result {
    .quick-buy__result {
        vertical-align: middle;
    }
}

.customer-account_body {
    .add-to-cart__off-canvas.is-open {
        &.is-open {
            width: 40rem;
        }

        &:not(.is-open) {
            transform: translateX(-40rem);
        }

        .product {
            display: flex;
            gap: 1rem;
            border-bottom: .1rem solid color(Gray, Gray-200);
            padding: 1rem;

            .product__info {
                flex: 1;

                h2 {
                    font-size: 1.8rem;
                    margin: 0 0 .5rem;
                    word-break: break-word;
                }

                > span {
                    display: block;
                    font-size: 1.2rem;
                }
            }

            .product__price {
                font-weight: 700;
                line-height: 2.7rem;
            }
        }

        .--actions {
            padding: 2rem;
            display: flex;
            justify-content: center;
            gap: 2rem;
        }
    }

    footer {
        position: relative;
        z-index: 5;
    }
}
