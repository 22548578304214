.global__content {
    font-size: 1.6rem;

    span, p {
        font-size: 1.6rem;

        a {
            font-size: 1.6rem;
        }
    }

    a {
        font-size: 1.6rem;
    }

    ul {
        margin: 0 0 2rem;
        list-style: disc inside;
        font-size: 1.6rem;
        line-height: 2.2rem;

        li {
            text-indent: -1em;
            padding-left: 1em;
        }
    }

    ol {
        margin: 0 0 2rem;
        list-style: none;
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
}
