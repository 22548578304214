header {
    .top__header {
        background: color(base, DefaultFontColor);
        color: color(base, White);
        position: relative;

        span {
            line-height: 4rem;
            font-weight: 600;
        }

        .header__brands-switcher {
            display: flex;
            align-items: center;
            position: relative;

            @include breakpoint(small only) {
                justify-content: space-between;
            }

            .header__brands--container {
                color: color(base, White);
                font-size: 1.4rem;
                line-height: 4rem;
                min-width: 30%;
                > span {
                    position: relative;
                    line-height: 4rem;
                    display: inline-block;
                    padding: 0 4rem 0 0;
                    font-weight: 600;
                    cursor: pointer;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: right 1rem center;
                    background-size: 2rem;
                    &.--active {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 15.75 7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                    }
                }

            }
            .header__brands--menu {
                display: none;
                position: absolute;
                z-index: 20;
                background: color(base, DefaultFontColor);
                border: .1rem solid color(gray, Gray-50);
                padding: 1rem;
                max-height: 50rem;
                overflow-y: auto;
                &.--active {
                    display: flex;
                    flex-direction: column;
                }
                a {
                    color: color(base, DefaultFontColor);
                    display: grid;
                    grid-template-columns: auto auto;
                    align-items: center;
                    justify-content: flex-start;
                    gap: .5rem;
                    width: 100%;
                    padding: 1rem .5rem;
                    border-bottom: .1rem solid color(base, White);
                    &:last-of-type {
                        border: none;
                    }

                    &:hover {
                        background: color(base, Black);
                    }
                    &.--active {
                        background: color(base, DefaultFontColor);
                    }
                    img {
                        max-height: 5rem;
                        min-width: max-content;
                        width: 100%;
                        flex: 1;
                    }
                    @-moz-document url-prefix() {
                        img {
                            min-width: 20rem;
                        }
                    }
                    > div {
                        line-height: 2.1rem;
                        max-width: 350px;
                        span {
                            color: color(base, White);
                            line-height: 2.1rem;
                        }
                    }
                }
            }
            .brands__reset {
                color: color(base, White);
                font-size: 1.4rem;
                line-height: 4rem;
                font-weight: 600;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 65 65' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Crect id='Artboard1' x='0' y='0' width='65' height='65' style='fill:none;'/%3E%3Cg id='Artboard11' serif:id='Artboard1'%3E%3Cg%3E%3Ccircle cx='7.672' cy='7.672' r='5.172' style='fill:%23bec0c1;'/%3E%3Ccircle cx='7.672' cy='32.5' r='5.172' style='fill:%23bec0c1;'/%3E%3Ccircle cx='7.672' cy='57.328' r='5.172' style='fill:%23bec0c1;'/%3E%3Ccircle cx='32.5' cy='7.672' r='5.172' style='fill:%23bec0c1;'/%3E%3Ccircle cx='32.5' cy='32.5' r='5.172' style='fill:%23bec0c1;'/%3E%3Ccircle cx='32.5' cy='57.328' r='5.172' style='fill:%23bec0c1;'/%3E%3Ccircle cx='57.328' cy='7.672' r='5.172' style='fill:%23bec0c1;'/%3E%3Ccircle cx='57.328' cy='32.5' r='5.172' style='fill:%23bec0c1;'/%3E%3Ccircle cx='57.328' cy='57.328' r='5.172' style='fill:%23bec0c1;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 1.6rem;
                background-position: left center;
                padding-left: 2.4rem;
            }
        }

        .header__quick__links {
            display: flex;
            justify-content: flex-end;

            li {
                a {
                    font-size: 1.2rem;
                    color: color(base, white);
                    text-transform: uppercase;
                    line-height: 40px;
                    padding-left: .8rem;

                    &:before {
                        content: "|";
                        padding-right: .8rem;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &:first-child {
                    a {
                        padding: 0;

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        p {
            display: block;
            text-align: center;
            line-height: 40px;
            color: color(base, White);
            font-size: 1.4rem;

            @include breakpoint(medium only) {
                text-align: left;
            }
        }

        .grid-x {
            @media screen and (max-width: 1285px) and (min-width: 1024px) {
                .medium-16.cell {
                    width: 75%;
                    margin-left: 0;

                    p {
                        text-align: left;
                    }
                }
            }
        }
    }
}
