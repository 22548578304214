$grid__max-width: 125.8rem;
$grid__gutter: 2rem;
$grid__max-padding: 2rem;

$grid__breakpoints: (
    small: 0,
    medium: 769,
    large: 1025,
    between-large-medium: 1100,
    xlarge: 1200,
    xxlarge: 1440,
    large-down: 1120,
) !default;

$default-font: 'Red Hat Display', sans-serif;
$fa-font-path: '/fonts';

$color-palette: (
    base: (
        White: rgb(255 255 255),
        Black: rgb(0 0 0),
        Primary: rgb(213, 19, 23),
        PrimaryHover: rgb(228, 76, 80),
        PrimaryButton: rgb(147, 19, 10),
        PrimaryButtonHover: rgb(213, 19, 23),
        SecondaryButton:rgb(255, 242, 4),
        SecondaryButtonHover:rgb(255, 247, 110),
        TertiaryButton:rgb(12, 186, 196),
        TertiaryButtonHover:rgb(10, 156, 164),
        Secondary: rgb(0 189 0),
        SecondaryHover: darken(rgb(0 189 0), 5%),
        DefaultFontColor: rgb(48, 48, 48),
        SemiSoldDark: rgb(48, 48, 48, .5),
        Green: rgb(105, 161, 118),
        Yellow: rgb(138, 109, 59),
        Brown: rgb(56, 57, 56),
        Red: rgb(204, 75, 55)
    ),
    Gray: (
        Gray-25: rgb(250 250 250),
        Gray-50: rgb(232 232 232),
        Gray-100: rgb(245 245 245),
        Gray-125: rgb(245, 245, 245),
        Gray-150: rgb(247, 248, 255),
        Gray-200: rgb(229 229 229),
        Gray-250: rgb(238, 238, 238),
        Gray-300: rgb(192, 192, 192),
        Gray-400: rgb(163 163 163),
        Gray-450: rgba(153, 153, 153, 1),
        Gray-500: rgb(139, 139, 138), // #8b8b8a
        Gray-600: rgb(82 82 82),
        Gray-700: rgb(64 64 64),
        Gray-750: rgb(56, 57, 56),
        Gray-800: rgb(38 38 38),
        Gray-900: rgb(29, 29, 27),
    ),
    Motoport: (
        Black: rgb(0,0,0),
        Red: rgb(213,19,23),
        DarkRed: rgb(147,19,10),
        Yellow: rgb(255,237,0),
        LightGrey: rgb(198,198,198),
        Grey: rgb(209,208,208),
        DarkGrey: rgb(87,87,86),
        Blue: rgb(11,46,75),
        LightBlue: rgb(143,164,186),
        MotoGrey: rgb(190, 192, 193),
    ),
    alert: (
        Info: rgb(30 66 159),
        Danger: rgb(241, 80, 80),
        Success: rgb(0 101 0),
        Warning: rgb(122 110 16),
        Error: rgb(155 28 28),
        Default: rgb(0 0 0),
    ),
    alert-backgrounds: (
        Info: rgb(195 221 253),
        Danger: rgb(251 213 213),
        Success: rgb(223 255 223),
        Warning: rgb(255 249 205),
        Error: rgb(251 213 213),
        Default: rgb(0 0 0),
    ),
);
