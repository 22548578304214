._category_description_content {
    position: relative;

    .image_container {
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 35rem;
            @include breakpoint(small only) {
                max-height: 25rem;
            }
        }
    }
}

.catalog__category-list {
    position: relative;

    .--loading {
        @include absolute-loading('light');
        align-items: flex-start;
        z-index: 2;

        > span {
            margin: 10%;
        }
    }

    @include breakpoint(medium) {
        > ul {
            border-top: .1rem solid color(gray, Gray-200);
            padding-top: 3rem;
        }
    }

    .product__tile {
        @include transition;
        padding-bottom: 2rem;
        height: calc(100% - 3rem);
        display: flex;
        flex-flow: column;
        margin-bottom: 3rem;
        border-bottom: .2rem solid color(gray, Gray-200);

        @include breakpoint(medium) {
            border-right: .1rem solid color(gray, Gray-200);
            border-bottom: none;
        }


        .product__swatches {
            display: flex;
            align-items: center;
            flex: 0 0 7.3rem;
            padding: 0 .5rem;

            @include breakpoint(medium) {
                padding: 0 2rem;
            }

            .swiper {
                flex: 1;
                max-width: 100%;

                .swiper-wrapper {
                    @include breakpoint(medium) {
                        &.--center {
                            justify-content: center;
                        }
                    }

                    .swiper-slide {
                        display: flex;
                        justify-content: center;

                        > span {
                            display: block;
                            aspect-ratio: 1;
                            cursor: pointer;
                            border-bottom: .3rem solid transparent;

                            @include hover() {
                                border-bottom-color: color(base, DefaultFontColor);
                            }
                        }
                    }
                }
            }
        }

        > a {
            display: flex;
            flex-flow: column;
            flex: 1;

            .image__holder {
                display: block;
                position: relative;
                aspect-ratio: 1;
                margin: 1rem;

                @include breakpoint(medium) {
                    margin: 2rem;
                }

                img {
                    @include transition;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .product__holder {
                display: flex;
                flex-flow: column;
                padding: 0 2rem;
                text-align: center;
                height: 100%;

                h3 {
                    @include transition;
                    font-weight: 700;
                    text-transform: initial;
                    color: color(base, DefaultFontColor);
                    flex: 1;
                    font-size: 1.6rem;

                    @include breakpoint(medium) {
                        font-size: 1.8rem;
                    }
                }

                ul {
                    margin: 1rem 0;
                    list-style: square;

                    li {
                        color: color(base, DefaultFontColor);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: .75rem;
                        font-size: 1rem;
                        line-height: 1.4rem;

                        @include breakpoint(medium) {
                            font-size: 1.2rem;
                            line-height: 2.1rem;
                        }

                        &::before {
                            content: '';
                            display: block;
                            height: .6rem;
                            width: .6rem;
                            background: color(Motoport, Grey);
                        }
                    }
                }

                .product__label {
                    height: 1.8rem;
                    display: block;
                    margin: 0 0 2rem;
                }

                .price__holder {
                    .price {
                        font-size: 1.6rem;
                        color: color(base, DefaultFontColor);
                        font-weight: 700;
                    }
                }
            }

            @include hover() {
                .image__holder {
                    img {
                        transform: scale(.9);
                    }
                }
                .product__holder {
                    h3 {
                        color: color(base, PrimaryButton);
                    }
                }
            }
        }

        .view__details {
            text-align: center;
            margin: 1.5rem 0 0;
        }
    }

    .category__placeholder {
        .--placeholder {
            display: block;
            aspect-ratio: 62/103;
            margin: 0 0 5rem;
            border-right: .1rem solid color(gray, Gray-200);
            padding: 0 2rem;

            > span {
                display: block;
                width: 100%;
                height: 100%;
                background-color: #F9F9F9;
            }
        }

        .--placeholder-line {
            display: block;
            width: 100%;
            height: .1rem;
            background-color: color(Gray, Gray-200);
            margin-bottom: 3rem;
        }
    }
}

.catalog__category-slider {
    > .swiper__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        left: -3.5rem;

        &.--next {
            left: auto;
            right: -3.5rem;
        }
    }
}

.catalog-category-page_body {
    .catalog__category-list {
        @include breakpoint(medium) {
            > ul {
                li:nth-child(3n) {
                    .product__tile {
                        border-right: none;
                    }
                }
            }
        }
    }
}

.homepage__product__container {
    .catalog__category-list {
        @include breakpoint(medium) {
            > ul {
                li:nth-child(4n) {
                    .product__tile {
                        border: none;
                    }
                }
            }
        }
    }
}
