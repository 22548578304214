@font-face {
    font-family: 'Barlow Condensed';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/BarlowCondensed-Regular.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/BarlowCondensed-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Barlow Condensed';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/BarlowCondensed-Medium.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/BarlowCondensed-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Barlow Condensed';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/BarlowCondensed-SemiBold.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/BarlowCondensed-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Barlow Condensed';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/BarlowCondensed-Bold.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/BarlowCondensed-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/RedHatDisplay-Regular.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/RedHatDisplay-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/RedHatDisplay-Medium.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/RedHatDisplay-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/RedHatDisplay-SemiBold.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/RedHatDisplay-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Barlow Condensed';
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/BarlowCondensed-ExtraBold.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/BarlowCondensed-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/RedHatDisplay-Black.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/RedHatDisplay-Black.woff') format('woff');
}

@font-face {
    font-family: 'PF DinDisplay Pro';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/motoport/assets/fonts/PFDinDisplayPro-Bold.woff2') format('woff2'),
    url('/area/web/motoport/assets/fonts/PFDinDisplayPro-Bold.woff') format('woff');
}

