.icon {
    display: flex;
    .svg-holder {
        display: block;
        width: 100%;
        background-repeat: no-repeat;
        background-position: top center;
        @include breakpoint(small only){
            background-position: center;
            background-size: 3.5rem;
        }
    }
}
