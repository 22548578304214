.checkout-success {
    margin: 0 auto;

    @include breakpoint(medium) {
        max-width: 100rem;
    }

    h1 {
        margin: 3rem 0 0;
    }

    p {
        font-size: 1.5rem;
        line-height: 2.5rem;

        &.--intro {
            margin: 1rem 0;
        }
    }

    a {
        font-size: 1.5rem;
    }

    .order__addresses {
        margin-top: 3rem;

        h4 {
            margin: 0 0 .5rem;
        }

        .order__address {
            background: color(Gray, Gray-100);
            padding: 1rem;
        }
    }
}
