.homepage__hotspot--block-wrapper {
    .hotspot__container {
        position: relative;
        display: flex;
        width: fit-content;
        margin: 0 auto;

        picture {
            display: flex;
        }

        .hotspot__item {
            position: absolute;
            z-index: 4;
            &.--active{
                z-index: 5;
            }

            .hotspot__item__content {
                > button {
                    width: 5.4rem;
                    height: 5.4rem;
                    border-radius: 50%;
                    padding: .7rem;
                    z-index: 4;
                    animation: 2s pulse 1s infinite forwards ease-in-out;

                    > span {
                        background-color: color(base, White);
                        display: flex;
                        width: 4rem;
                        height: 4rem;
                        border-radius: 50%;
                        border: .2rem solid transparent;
                        font-size: 2.2rem;
                        justify-content: center;
                        align-items: center;

                        svg {
                            width: 2.2rem;
                            height: 2.2rem;
                        }
                    }

                    &.--small {
                        width: 4.2rem;
                        height: 4.2rem;
                        padding: .6rem;

                        > span {
                            width: 3rem;
                            height: 3rem;
                            font-size: 1.6rem;

                            svg {
                                width: 1.6rem;
                                height: 1.6rem;
                            }
                        }
                    }
                }
            }

            .hotspot__item__detail {
                @include border-radius();
                z-index: 5;
                position: absolute;
                left: 3rem;
                top: 3rem;
                width: 24rem;
                text-align: left;
                background-color: rgba(255, 255, 255, 0.9);
                padding: 0 1rem 1rem;

                @include breakpoint(small only) {
                    width: 18rem;
                }

                &.--right {
                    right: 3rem;
                    left: auto;
                }

                &.--bottom {
                    top: auto;
                    bottom: 3rem;
                }

                .--header {
                    display: flex;
                    justify-content: flex-end;
                    margin: 0.5rem 0;

                    .--close {
                        cursor: pointer;
                        width: 2rem;
                        height: 2rem;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                        background-size: 1.8rem;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                .plain__holder, .product__holder {
                    img {
                        margin: 0 0 1rem;
                    }

                    h4 {
                        font-size: 1.8rem;
                        line-height: 2.4rem;
                        margin-bottom: 1rem;
                    }

                    .--button {
                        margin: 1rem 0 0;
                    }

                    > a:not(.--button) {
                        font-size: 1.4rem;
                        text-decoration: underline;
                        color: color(base, DefaultFontColor);
                    }
                }
            }
        }

        > .--button {
            position: absolute;
            z-index: 3;
            bottom: 2rem;
            right: 2rem;
        }
    }
}

@keyframes pulse {
    50% {
        background-color: inherit;
    }
}
