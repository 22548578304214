footer {
    margin: 5rem 0 0;

    p:empty {
        display: none;
    }

    h5 {
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 32px;
        color: color(base, White);
        @include breakpoint(small only) {
            font-size: 1.4rem
        }
    }

    .footer__links {
        ul {
            a {
                @include transition();
                display: inline-flex;
                color: color(base, White);
                font-size: 1.6rem;
                line-height: 3.2rem;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 1rem;

                @include hover() {
                    padding: 0 0 0 1.5rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                }
            }
        }
        @include breakpoint(small only) {
            ul {
                display: none;
            }
            h5 {
                border-top: .1rem solid color(base, White);
                padding: .5rem 0;
                &:after {
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 6v12m6-6H6' /%3E%3C/svg%3E%0A");
                    background-size: 2rem;
                    background-position: left center;
                    display: block;
                    width: 2rem;
                    height: 2rem;
                    float: right;
                    line-height: 36px;
                }
            }
            &.opened {
                padding-bottom: 1rem;
                h5 {
                    &:after {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M18 12H6' /%3E%3C/svg%3E%0A");
                    }
                }
                &:not(._partners) {
                    ul {
                        flex-direction: column;
                    }
                }
                ul {
                    display: flex;
                }
            }
        }
    }

    .footer {
        background: color(base, DefaultFontColor);
        padding: 6rem 0;
        @include breakpoint(medium only) {
            padding: 6rem 3rem;
        }
        @include breakpoint(small only) {
            padding: 2rem 0;
        }

        p {
            color: color(base, White);
        }

        ul {
            li {
                a {
                    color: color(base, White);
                    font-size: 1.6rem;
                    line-height: 3.2rem;
                    @include breakpoint(small only) {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        ._partners {
            ul {
                flex-wrap: wrap;
                @include breakpoint(medium) {
                    display: flex;
                }

                li {
                    flex-basis: 50%;
                }
            }
        }
    }

    .checkout__footer {
        display: none;
        text-align: center;

        h5 {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.8rem;
        }

        a {
            display: inline-block;
            margin: 1rem auto 0;
        }
    }
}
