.media__gallery {
    position: relative;

    .media__placeholder {
        display: flex;
        gap: 2rem;

        span {
            &:first-of-type {
                flex: 0 0 10rem;
            }

            &:last-of-type {
                aspect-ratio: 1;
                @media screen and (max-width: 425px) {
                    max-height: 40.5rem;
                }
            }
        }
    }

    .product__media-container {
        display: flex;
        gap: 2rem;
        max-height: 61rem;

        &.--absolute {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .--thumbnails {
            overflow: hidden;
            flex: 0 0 10rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;

            .swiper {
                height: 53.5rem;

                .swiper-slide {
                    height: auto !important;

                    > span {
                        display: block;
                        cursor: pointer;
                    }
                }
            }

            .--prev {
                transform: rotate(90deg);
            }

            .--next {
                transform: rotate(90deg);
            }
        }

        .--images {
            flex: 1;
            overflow: hidden;

            .swiper-slide {
                img {
                    margin: 0 auto;
                    cursor: zoom-in;
                }
            }
        }
    }

    .fullscreen__container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background-color: color(base, White);
        display: flex;
        flex-flow: column;

        .--header {
            padding: 2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > button {
                @include transition();
                display: block;
                width: 3.2rem;
                height: 3.2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Gray, Gray-500)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");

                @include hover() {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                }
            }
        }

        .product__media-container--fullscreen {
            display: flex;
            flex-flow: column;
            flex: 1;
            max-height: 100%;
            overflow: hidden;
            gap: 1rem;

            .--images {
                flex: 1;
                overflow: hidden;
                display: flex;
                align-items: center;
                gap: 2rem;
                padding: 0 1rem;

                .swiper {
                    height: 100%;
                    width: 100%;

                    .swiper-slide {
                        img {
                            margin: 0 auto;
                            object-fit: scale-down;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .--thumbnails {
                border-top: .1rem solid color(Gray, Gray-100);
                padding: 2rem 0;

                .--wrapper {
                    display: flex;
                    gap: 2rem;
                    align-items: center;
                    width: fit-content;
                    margin: 0 auto;

                    .swiper {
                        @include breakpoint(medium) {
                            width: 53.5rem;
                        }

                        .swiper-slide {
                            > span {
                                display: flex;
                                cursor: pointer;
                                border: .1rem solid color(Gray, Gray-300);

                                @include hover() {
                                    border-color: color(base, DefaultFontColor);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
