footer {
    .newsletter__block {
        @include breakpoint(small only) {
            text-align: center;

            h5 {
                padding-top: 1rem;
            }
        }

        p {
            font-size: 1.4rem;
        }


        .footer__form {
            .newsletter__container-form {
                display: flex;
                padding: 3rem 0 0;
                gap: 2rem;

                @include breakpoint(small only) {
                    flex-direction: column;
                }

                input {
                    flex-basis: 65%;
                    @include breakpoint(small only) {
                        flex-basis: 100%;
                        text-align: center;
                    }
                }

                button {
                    border-radius: .3rem;
                    white-space: nowrap;
                }
            }
        }
    }
}
