.language__dropdown {
    gap: 1rem;
    z-index: 99;

    @include breakpoint(medium) {
        display: flex;
        justify-content: flex-end;
    }
    @include breakpoint(small only) {
        display: flex;
        align-items: center;
        gap: .5rem;
        justify-content: flex-end;
    }

    .language__countries {
        cursor: pointer;
        position: relative;

        @include breakpoint(small only) {
            section {
                display: flex;

                > a {
                    color: color(base, White) !important;
                    margin-left: auto;
                    padding: 0 2rem;
                }
            }
        }

        &:hover {
            .language__dropdown {
                display: block;
                z-index: 11;
            }
        }

        .selected {
            display: flex;
            gap: .5rem;
            @include breakpoint(small only) {
                display: inline-flex;
            }

            > span {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='4' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                background-position: right center;
                background-repeat: no-repeat;
                background-size: 1.4rem;
                display: block;
                font-size: 1.4rem;
                font-weight: 700;
                padding: 0 1.6rem 0 .2rem;
                text-transform: uppercase;
                @include breakpoint(small only) {
                    padding: 0 1.6rem 0 .2rem;
                    color: color(base, White);
                }
            }
        }

        .language__dropdown {
                @include border-radius();
                background: color(base, White);
                border: .1rem solid color(gray, Gray-300);
                display: none;
                position: absolute;
                right: 0;
                left: -2.5rem;
                top: 4rem;

                &::before {
                    border-color: transparent transparent color(gray, Gray-300) transparent;
                    border-style: solid;
                    border-width: 0 .6rem .8rem .6rem;
                    bottom: 100%;
                    content: '';
                    height: 0;
                    position: absolute;
                    right: 1.2rem;
                    width: 0;
                }

            ul {
                li {
                    a {
                        @include transition;
                        border: none;
                        gap: .8rem;
                        line-height: normal;
                        padding: .5rem;

                        img {
                            height: 1.5rem;
                            width: 1.8rem;
                            margin: 0 auto;
                            @include breakpoint(small only) {
                                margin: 0;
                            }
                        }

                        @include breakpoint(medium) {
                            align-items: center;
                            display: flex;
                        }

                        @include breakpoint(small only) {
                            text-align: center;
                            display: flex;
                            justify-content: center;
                        }

                        @include hover {
                            background: color(gray, Gray-250);
                        }

                        span {
                            color: color(base, White);
                            display: block;
                            font-size: 1.4rem;
                            font-weight: 700;
                            text-transform: uppercase;

                            @include breakpoint(small only) {
                                color: color(base, DefaultFontColor);
                            }
                            @include breakpoint(medium) {
                                color: color(base, DefaultFontColor);
                                font-size: 1.2rem;
                                padding-right: .5rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .customer__account-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z' /%3E%3C/svg%3E%0A");
        background-size: 2rem;
        background-position: center;
        background-repeat: no-repeat;
        width: 3.5rem;
        height: 3.5rem;
        display: block;
    }
}

.top__menu {
    .customer__account-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, DefaultFontColor)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z' /%3E%3C/svg%3E%0A");
    }
    .language__dropdown {
        .language__countries {
            .selected {
                > span {
                    color: color(base, DefaultFontColor);
                }
            }
        }
    }
}
