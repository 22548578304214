.products_by_bike__container {
    @include breakpoint(small only) {
        margin: 0;
    }
    #offCanvas-filter {
        @include breakpoint(small only) {
            transform: translateX(-100%);
        }
    }
    .product_advanced_filter {
        background: color(base, DefaultFontColor);
        padding: 3rem 3rem 4rem;
        margin: 0 4rem 4rem;
        @include breakpoint(small only) {
            margin: 4rem 0;
        }
         .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3rem;
            h2  {
                color: color(base, White);
            }
            button {
                font-size: 1.6rem;
                color: color(base, PrimaryButton);
                text-decoration: underline;
                font-weight: 700;
            }
        }

        h4 {
            color: color(base, White);
            margin-bottom: 1rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{#f60}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12' /%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 3rem;
            background-size: 2rem;
            &.-active {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{#00BD00FF}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12' /%3E%3C/svg%3E%0A");
            }
        }
        .product_advanced_filter__filters {
            form {
                display: flex;
                justify-content: flex-start;
                gap: 1rem;
                flex-wrap: wrap;
                align-items: flex-end;
                .filter_step {
                    flex: 1 1 0;
                    width: 0;
                    @include breakpoint(small only) {
                        width: 100%;
                        flex: 1 1 100%;
                    }
                }
                select {
                    width: 100%;
                    @include breakpoint(small only) {
                        min-width: 25rem;
                        width: 100%;
                    }
                }
                button {
                    margin-left: 2rem;
                    height: 4rem;
                    @include breakpoint(small only) {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }
    .products__by_bike-results {
        .products__by_bike-results-filters {
        }
        .products__by_bike-results-products {
            .catalog-category__toolbar {
                &.--top {
                    .toolbar__top {
                        display: flex;
                        align-items: center;
                        margin: 0 0 4rem;
                        justify-content: space-between;
                        .product__count {
                            line-height: 4rem;
                            color: color(gray, Gray-300);
                        }
                        .--actions {
                            display: flex;
                            gap: 1rem;
                            font-size: 1.4rem;
                        }
                    }
                    .catalog-category__sorter {
                        label {
                            font-size: 1.4rem;
                            line-height: 4rem;
                        }
                    }
                }
            }
        }
    }
}
